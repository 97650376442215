<template>
  <div class="albumDetail" :style="{ background: info.backcolor }">
    <div class="bg">
      <img :src="info.pic" alt="" />
    </div>
    <div class="header">
      <div
        class="left"
        :class="info.background_depth === 0 ? 'light' : null"
        @click="$router.back()"
      ></div>
    </div>
    <div class="pd15">
      <div class="info">
        <!-- <img :src="info.pic" alt="" /> -->
        <div class="title" v-if="info.titleDisplay == 1">{{ info.name }}</div>
        <div
          class="subTitle"
          :style="{ background: info.cardcolor, color: info.cardwordcolor }"
        >
          {{ info.describe }}
        </div>
      </div>
      <div class="line" :style="{ color: info.cardwordcolor }">
        <i :style="{ background: info.cardwordcolor }"></i>
        本系列共{{ list.length }}款藏品
        <i :style="{ background: info.cardwordcolor }"></i>
      </div>
      <div>
        <div class="list">
          <div
            class="listItem"
            :style="{ background: info.cardcolor }"
            v-for="(item, key) in list"
            :key="item.id"
            @click="goTo(item)"
          >
            <div class="left" v-if="key % 2 === 0">
              <img :src="item.goods.listPic" alt="" />
            </div>
            <div class="right" v-if="key % 2 === 0">
              <div class="title" :style="{ color: info.cardwordcolor }">
                {{ item.goods.name }}
              </div>
              <div class="subTitle" :style="{ color: info.cardwordcolor }">
                {{ item.shortDesc }}
              </div>
            </div>
            <div class="right" v-if="key % 2 !== 0">
              <div class="title" :style="{ color: info.cardwordcolor }">
                {{ item.goods.name }}
              </div>
              <div class="subTitle" :style="{ color: info.cardwordcolor }">
                {{ item.shortDesc }}
              </div>
            </div>
            <div class="left" v-if="key % 2 !== 0">
              <img :src="item.goods.listPic" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlbumDetail',
  data() {
    return {
      query: {
        pageindex: 1,
        pagesize: 20,
        id: 0,
      },
      info: {},
      list: [],
    };
  },
  mounted() {
    this.query.id = this.$route.query.id;
    this.getDetail();
  },
  methods: {
    goTo(item) {
      this.$router.push(`/particulars?id=${item.goods_id}&add=0`);
    },
    getDetail() {
      const query = {
        ...this.query,
        token: localStorage.getItem('token'),
      };
      this.$api.goodsAlbumInfoById(query).then((e) => {
        if (e.code === 0) {
          this.info = e.data.info;
          this.list = e.data.list;
        } else {
          this.$toast(e.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.albumDetail {
  background: #000;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  // padding: 0 15px;
  overflow: auto;
  padding-bottom: 20px;
  .pd15 {
    padding: 0 15px;
  }
  .bg {
    img {
      max-width: 100%;
    }
  }
  .header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 50px;
    z-index: 9999;
    .left {
      position: absolute;
      left: 20px;
      top: 50%;
      width: 10px;
      height: 10px;
      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
      transform: rotate(-45deg) translateY(-50%);
      &.light {
        border-top: 1px solid #000;
        border-left: 1px solid #000;
      }
    }
  }
  .info {
    position: relative;
    img {
      width: 100%;
    }
    .title {
      font-size: 19px;
      color: #fff;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 32px;
      text-align: center;
      margin-top: -45px;
    }
    .subTitle {
      margin-top: 18px;
      padding: 15px;
      background: #2b2a2c;
      box-shadow: 0px 4px 14px 2px rgba(1, 15, 45, 0.05);
      border-radius: 10px;
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #c0c0c0;
      line-height: 21px;
    }
  }
  .line {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ececec;
    line-height: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0 15px;
    i {
      margin: 0 7px;
      display: block;
      width: 21px;
      height: 1px;
      background: #ececec;
    }
  }
  .list {
    .listItem {
      margin-top: 20px;
      padding: 13px 11px;
      background: #2b2a2c;
      box-shadow: 0px 4px 14px 2px rgba(1, 15, 45, 0.05);
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        width: 80px;
        height: 80px;
        border-radius: 10px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
      .right {
        flex: 1;
        margin-right: 13px;
        min-width: 1px;
        .title {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
        .subTitle {
          margin-top: 7px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #c0c0c0;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -moz-box;
          -moz-line-clamp: 2;
          -moz-box-orient: vertical;
          overflow-wrap: break-word;
          word-break: break-all;
          white-space: normal;
          overflow: hidden;
        }
      }
      &:nth-child(odd) {
        .right {
          margin-left: 13px;
          margin-right: 0;
        }
      }
    }
  }
}
</style>
